import React from "react";
import { graphql } from "gatsby";
import BlocTitle from "../components/elements/bloc-title";
import Layout from "../components/layout";
import { Helmet } from "react-helmet";

const ContactPage = ({ data }) => {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{data.strapiContact.SEO.metaTitle}</title>
        <meta
          name="description"
          content={data.strapiContact.SEO.metaDescription}
        />

        {/* <link rel="canonical" href="http://mysite.com/example" /> */}
      </Helmet>
      <div className="bg-black font-gotham text-white">
        <div className="-space-y-2 bg-yellow pt-2 pb-1 text-center">
          <BlocTitle
            styleName="text-xl lg:text-3xl font-gotham-ultra"
            content={data.strapiContact.title}
          />
        </div>

        <form
          name="contact-form"
          method="POST"
          data-netlify="true"
          className="mx-auto grid max-w-screen-md grid-cols-2 gap-4 px-6 pt-6 lg:px-4 lg:pt-12"
          netlify-honeypot="bot-field"
          action="/contact"
        >
          <input type="hidden" name="contact-form" value="contact-form" />
          <div hidden>
            <label>
              Don’t fill this out: <input name="bot-field" />
            </label>
          </div>
          <div className="col-span-2 lg:col-span-1">
            <label
              htmlfor="name"
              className="text-gray-700 block text-sm font-medium"
            >
              Votre Nom
            </label>
            <div className="mt-1">
              <input
                type="text"
                name="name"
                id="name"
                className="border-gray-300 block w-full rounded-md text-black shadow-sm focus:border-yellow focus:ring-yellow sm:text-sm"
                placeholder="Prénom Nom"
              />
            </div>
          </div>
          <div className="col-span-2 lg:col-span-1">
            <label
              htmlfor="email"
              className="text-gray-700 block text-sm font-medium"
            >
              Votre Email
            </label>
            <div className="mt-1">
              <input
                type="email"
                name="email"
                id="email"
                className="border-gray-300 block w-full rounded-md text-black shadow-sm focus:border-yellow focus:ring-yellow sm:text-sm"
                placeholder="you@example.com"
              />
            </div>
          </div>
          <div className="col-span-2">
            <label
              htmlfor="comment"
              className="text-gray-700 block text-sm font-medium"
            >
              Dites-nous tout :
            </label>
            <div className="mt-1">
              <textarea
                rows="4"
                name="comment"
                id="comment"
                className="border-gray-300 block w-full rounded-md text-black shadow-sm focus:border-yellow focus:ring-yellow sm:text-sm"
              ></textarea>
            </div>
          </div>

          <button
            type="submit"
            className="focus:ring-indigo-500 col-span-2 rounded-md border border-transparent bg-yellow py-2 px-4 font-gotham text-sm font-medium uppercase text-white shadow-sm hover:bg-yellow focus:outline-none focus:ring-2 focus:ring-offset-2"
          >
            Envoyer Mon Message
          </button>
        </form>
        <div
          className="flex justify-center px-3 
 pt-16 pb-12 text-center lg:text-base"
        >
          <div
            className="lg:leading-loose"
            dangerouslySetInnerHTML={{
              __html: data.strapiContact.content.data.content,
            }}
          />
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query strapiContactQuery {
    strapiContact {
      title
      content {
        data {
          content
        }
      }
      SEO {
        metaTitle
        metaDescription
      }
    }
  }
`;

export default ContactPage;
